<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Property Matching</div>
            </v-card-title>

            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="property_matching"
              class="mt-4"
              no-data-text="No Property Matching found"
            >
              <template v-slot:item.property_type="{ item }">
                <div
                  v-if="property.type == item.property_type"
                  class="green--text"
                >
                  {{ item.property_type }}
                </div>
                <div v-else>{{ item.property_type }}</div>
              </template>

              <template v-slot:item.location="{ item }">
                <div v-if="item.location != ''">
                  <div
                    v-for="item in item.location.split(',')"
                    v-bind:key="item"
                  >
                    <div
                      v-if="
                        item != '' &&
                        (property.address.short.includes(item) ||
                          (property.address.address2 != null &&
                            property.address.address2.includes(item)))
                      "
                    >
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:item.min_price="{ item }">
                <div
                  v-if="
                    property.current_price.price >= parseInt(item.min_price)
                  "
                  class="green--text"
                >
                  {{ item.min_price }}
                </div>
                <div v-else>{{ item.min_price }}</div>
              </template>

              <template v-slot:item.max_price="{ item }">
                <div
                  v-if="
                    property.current_price.price <= parseInt(item.max_price)
                  "
                  class="green--text"
                >
                  {{ item.max_price }}
                </div>
                <div v-else>{{ item.max_price }}</div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      v-on="on"
                      class="mr-2"
                      @click="openSubmissionDialog(item)"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>

                <v-btn
                  v-if="checkIfSend(item)"
                  x-small
                  depressed
                  color="grey lighten-4 grey--text"
                  class="mr-2"
                  @click="openSendEmail(item)"
                >
                  <v-icon x-small>mdi-email</v-icon>
                </v-btn>

                <v-btn
                  v-else
                  x-small
                  depressed
                  color="blue lighten-4 blue--text"
                  class="mr-2"
                  @click="openSendEmail(item)"
                >
                  <v-icon x-small>mdi-email</v-icon>
                </v-btn>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openDeleteMatching(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="submissionDialog.open"
        scrollable
        max-width="1000"
        @click:outside="resetSubmissionDialog()"
      >
        <v-card>
          <v-card-title class="headline">View Submission</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5 grey lighten-4">
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>{{ submissionDialog.submission.full_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ submissionDialog.submission.email }}</td>
                </tr>
                <tr
                  v-for="(input, name) in submissionDialog.submission.content"
                  :key="name"
                >
                  <th>{{ name }}</th>
                  <td
                    style="padding-top: 8px; padding-bottom: 8px"
                    v-html="input"
                  ></td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-simple-table class="mt-5">
              <tbody>
                <tr>
                  <th>Submitted At</th>
                  <td>
                    {{ submissionDialog.submission.formatted_dates.created_at }}
                  </td>
                </tr>
                <tr>
                  <th>Form Name</th>
                  <td>{{ submissionDialog.submission.form_name }}</td>
                </tr>
                <tr>
                  <th>IP Address</th>
                  <td>{{ submissionDialog.submission.ip_address }}</td>
                </tr>
                <tr>
                  <th>Page URL</th>
                  <td>/{{ submissionDialog.submission.page_url }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="resetSubmissionDialog()"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteMatching.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline"
            >Archive Property Matching</v-card-title
          >
          <v-card-text>Are you sure you wish to archive ?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDeleteMatching"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteMatching.loading"
              @click="saveDeleteMatching"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="sendEmail.dialog" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Send Email</v-card-title>
          <v-card-text>Send an email to this customer?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetSendEmail">No</v-btn>
            <v-btn
              color="success"
              depressed
              :loading="sendEmail.loading"
              @click="sendEmailBalance"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Property Matching",
          disabled: true,
        },
      ],

      submissionDialog: {
        open: false,
        submission: {
          full_name: null,
          email: null,
          content: [],
          form_name: null,
          page_url: null,
          formatted_dates: {
            created_at: null,
          },
        },
      },
      resetSubmissionDialog() {
        this.submissionDialog.open = false;
        this.submissionDialog.submission = {
          full_name: null,
          email: null,
          content: [],
          form_name: null,
          page_url: null,
          formatted_dates: {
            created_at: null,
          },
        };
      },
      deleteMatching: {
        dialog: false,
        matching: {},
        loading: false,
      },
      sendEmail: {
        dialog: false,
        matching: {},
        loading: false,
      },
      tableHeaders: [
        { text: "Full Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Location", value: "location" },
        { text: "Property Type", value: "property_type" },
        { text: "Price Min", value: "min_price" },
        { text: "Price Max", value: "max_price" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },

    property_matching() {
      let submissions =
        this.$store.getters["melrose/propertiesStore/submissions"];

      submissions.forEach((slot) => {
        slot.min_price = slot.content["Min Price"];
        slot.max_price = slot.content["Max Price"];
        slot.location = slot.content["Location"];
        slot.property_type = slot.content["Property Type"];
      });

      return submissions;
    },
  },
  methods: {
    checkIfSend(item) {
      const found = item.replays.find(
        (element) => element.connection_id === this.property.id
      );

      return found;
    },

    openSendEmail(matching) {
      this.sendEmail.dialog = true;
      this.sendEmail.matching = matching;
    },

    resetSendEmail() {
      this.sendEmail.dialog = false;
      this.sendEmail.loading = false;
      this.sendEmail.matching = {};
    },

    sendEmailBalance() {
      this.sendEmail.loading = true;

      this.$store
        .dispatch("melrose/propertiesStore/emailSend", {
          appId: this.$route.params.id,
          submissionId: this.sendEmail.matching.id,
          propertyId: this.$route.params.propertyId,
        })
        .then(() => {
          this.resetSendEmail();
        })
        .catch(() => {
          this.sendEmail.loading = false;
        });
    },
    openSubmissionDialog(submission) {
      this.submissionDialog.submission = submission;
      this.submissionDialog.open = true;
    },

    openDeleteMatching(matching) {
      this.deleteMatching.matching = matching;
      this.deleteMatching.dialog = true;
    },

    resetDeleteMatching() {
      this.deleteMatching.dialog = false;
      this.deleteMatching.matching = {};
      this.deleteMatching.loading = false;
    },

    saveDeleteMatching() {
      this.deleteMatching.loading = true;

      this.$store
        .dispatch("contactForms/deleteSubmission", {
          appId: this.$route.params.id,
          submissionId: this.deleteMatching.matching.id,
        })
        .then(() => {
          this.resetDeleteMatching();
        })
        .catch(() => {
          this.deleteMatching.loading = false;
        });
    },
  },
};
</script>
